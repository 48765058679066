<template>

  <layout-vertical>

    <router-view v-if="loaded" />
    <b-spinner
      v-else
      variant="primary"
    />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>
    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import { BSpinner } from 'bootstrap-vue'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    BSpinner,
  },
  data() {
    return {
      loaded: false,
      autoHeartbeat: undefined,
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
  beforeDestroy() {
    clearInterval(this.autoHeartbeat)
  },
  async mounted() {
    const { Organizations, fullName } = JSON.parse(localStorage.getItem('userData'))

    this.$store.commit('organizations/setOrganizations', [])
    this.$store.commit('suborganizations/setSuborganizations', [])
    this.$store.commit('clusters/setClusters', [])
    this.$store.commit('localGroups/setLocalGroups', [])
    this.$store.commit('emmas/setEmmas', [])

    const results = []

    for (let i = 0; i < Organizations.length; i += 1) {
      results.push(this.$store.dispatch('organizations/pullOrganizationById', Organizations[i].id))
      results.push(this.$store.dispatch('suborganizations/pullSuborganizationsByOrgId', Organizations[i].id))
      results.push(this.$store.dispatch('clusters/pullClustersByOrgId', Organizations[i].id))
      results.push(this.$store.dispatch('localGroups/pullLocalGroupsByOrgId', Organizations[i].id))
      results.push(this.$store.dispatch('emmas/addOrganizationEmmas', Organizations[i].id))
    }

    await Promise.all(results)

    if (fullName === 'Matthias Schafstädt' || fullName === 'Demo User') {
      try {
        await this.$store.dispatch('emmas/createFakeEmmas', 50)
        this.autoHeartbeat = setInterval(this.randomHeartbeats, 10000)
      } catch (e) {
        console.error(e)
      }
    } else if (fullName === 'Fraunhofer Institut') {
      await this.$store.dispatch('emmas/addFraunhoferEmma')
    }
    await this.$store.dispatch('emmas/updateEmmaHeartbeats')

    await this.$store.dispatch('organizations/spoofHistory')
    await this.$store.dispatch('suborganizations/spoofHistory')
    await this.$store.dispatch('clusters/spoofHistory')
    await this.$store.dispatch('localGroups/spoofHistory')

    this.loaded = true
  },
  methods: {
    randomHeartbeats() {
      this.$store.commit('emmas/triggerRandomHeartbeats')
    },
  },
}
</script>
